import React, { lazy, Suspense, Fragment, useContext } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ClimbingBoxLoader } from 'react-spinners';

import {
  LeftSidebar,
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';

import { UserContext } from "./config/Providers/UserProvider";

const DashboardProfileData = lazy(() => import('./pages/ProfilePage'));
const DashboardHome = lazy(() => import('./pages/DashboardHome'));
const LoginPage = lazy(() => import('./pages/LoginPage'));
const DashboardMostActiveUsers = lazy(() => import('./pages/MostActiveUsers'));

const LandingPage = lazy(() => import('./tend-dashboard-pages/LandingPage'));
const LoadingIndicators = lazy(() => import('./tend-dashboard-pages/LoadingIndicators'));

// const Routes = () => {
function Routes() {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ClimbingBoxLoader color={'#3c44b1'} loading={true} />
          </div>
        </div>
      </Fragment>
    );
  };

  const user = useContext(UserContext);

  return (
    <AnimatePresence>
      <Suspense fallback={<SuspenseLoading />}>
        {/* <Switch> */}
        {user ?
          <Redirect exact from="/" to="/DashboardHome" />
          :
          <Redirect exact from="/" to="/LoginPage" />}

        <Route path={['/LandingPage']}>
          <PresentationLayout>
            <Switch location={location} key={location.pathname}>
              <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={pageVariants}
                transition={pageTransition}>
                <Route path="/LandingPage" component={LandingPage} />
              </motion.div>
            </Switch>
          </PresentationLayout>
        </Route>

        {!user ?
          <Route
            path={[
              '/LoginPage'
            ]}>
            <MinimalLayout>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/LoginPage" component={LoginPage} />
                </motion.div>
              </Switch>
            </MinimalLayout>
          </Route>
          :
          <Route
            path={[
              '/DashboardHome',
              '/DashboardProfileData',
              '/DashboardMostActiveUsers'
            ]}>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route
                    path="/DashboardHome"
                    component={DashboardHome}
                  />
                  <Route
                    path="/DashboardProfileData"
                    component={DashboardProfileData}
                  />
                  <Route
                    path="/LoadingIndicators"
                    component={LoadingIndicators}
                  />
                  <Route
                    path="/DashboardMostActiveUsers"
                    component={DashboardMostActiveUsers}
                  />                  
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>
        }
        {/* </Switch> */}
      </Suspense>
    </AnimatePresence>
  );
};

export default Routes;
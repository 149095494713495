import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/storage';

const config = {
  apiKey: "AIzaSyCOdQ-E3QoT-0WAUpRwZRtwXVQs1hn6yNo",
  authDomain: "tend-ef3be.firebaseapp.com",
  databaseURL: "https://tend-ef3be.firebaseio.com",
  projectId: "tend-ef3be",
  storageBucket: "tend-ef3be.appspot.com",
  messagingSenderId: "306909681833",
  appId: "1:306909681833:web:de497ed13131ae1e0231b8",
  measurementId: "G-3PVCBPDBWF"
};
/*
const config = {
  apiKey: "AIzaSyA2aZqu7BmE1O0lGhE9gSuVnVVxZ-vfBz4",
  authDomain: "tend-staging-d1b77.firebaseapp.com",
  databaseURL: "https://tend-staging-d1b77.firebaseio.com",
  projectId: "tend-staging-d1b77",
  storageBucket: "tend-staging-d1b77.appspot.com",
  messagingSenderId: "535696119678",
  appId: "1:535696119678:web:c0519688dff3dcac1f052f",
  measurementId: "G-V469YE18FR"
};
*/
/*const config = {
  apiKey: "AIzaSyAYUXU6u89ZLYWpP5F0y0UmwoVdkcx855M",
  authDomain: "tend-test-f86c4.firebaseapp.com",
  databaseURL: "https://tend-test-f86c4.firebaseio.com",
  projectId: "tend-test-f86c4",
  storageBucket: "tend-test-f86c4.appspot.com",
  messagingSenderId: "936458972389",
  appId: "1:936458972389:web:71b5421574e823a2b0b80c",
  measurementId: "G-7CFB6FSCLN"
};
*/
app.initializeApp(config);
export const auth = app.auth();
export const firestore = app.firestore();

import React, { Fragment, Component } from 'react';

export default class SidebarFooter extends Component {
  render() {
    return (
      <Fragment>
        <div className="app-sidebar--footer">
          <div>Tend Dashboard</div>
        </div>
      </Fragment>
    );
  }
}

import React, { Fragment, Component } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';

import { connect } from 'react-redux';
import RouterLink from '../ReactMetismenuRouterLink';

import MetisMenu from 'react-metismenu';

import { SidebarUserbox } from '../../layout-components';

const sidebarMenuContent = [
  {
    label: 'Analytics',
    icon: 'pe-7s-safe',
    content: [
      {
        label: 'Dashboard',
        // description:
        //   'Dashboard',
        to: '/DashboardHome'
      },
      {
        label: 'Profile Data',
        // description:
        //   'This is a dashboard page for the Tend Profile Data.',
        to: '/DashboardProfileData'
      },
      {
        label: 'Most Active Users',
        // description:
        //   'This is a dashboard page for the most active users.',
        to: '/DashboardMostActiveUsers'
      },
      // {
      //   label: 'Category & Task Data',
      //   // description:
      //   //   'This is a dashboard page for the Tend Task Data.',
      //   to: '/TasksPage'
      // },
    ]
  }
];

class SidebarMenu extends Component {
  render() {
    let { sidebarUserbox } = this.props;
    return (
      <Fragment>
        <PerfectScrollbar>
          {sidebarUserbox && <SidebarUserbox />}
          <div className="sidebar-navigation">
            <div className="sidebar-header">
              <span>Navigation menu</span>
            </div>
            <MetisMenu
              content={sidebarMenuContent}
              LinkComponent={RouterLink}
              activeLinkFromLocation
              iconNamePrefix=""
              noBuiltInClassNames={true}
              classNameItemActive="active"
              classNameIcon="sidebar-icon"
              iconNameStateVisible="sidebar-icon-indicator"
              iconNameStateHidden="sidebar-icon-indicator"
              classNameItemHasVisibleChild="submenu-open"
              classNameItemHasActiveChild="active"
              classNameStateIcon="pe-7s-angle-down"
            />
          </div>
        </PerfectScrollbar>
      </Fragment>
    );
  }
}
const mapStateToProps = state => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox
});

export default connect(mapStateToProps)(SidebarMenu);
